import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import ResolveRatification from './ResolveRatification';

const RejectRatification = ({ selectedInvoiceIds, setSelectedInvoices }) => {
  const stepToTitle = {
    0: 'Seleccione el motivo por qué se rechazan estas facturas',
    1: 'Ingrese el contacto que rechazó la ratificación (si corresponde)',
    2: 'Adjuntar archivo',
  };
  return (
    <ResolveRatification
      selectedInvoiceIds={selectedInvoiceIds}
      setSelectedInvoices={setSelectedInvoices}
      accepted={false}
      stepToTitle={stepToTitle}
      loadingIconColor="error"
      loadingIcon={<CloseIcon />}
      iconTooltip="Rechazar"
    />
  );
};

RejectRatification.propTypes = {
  selectedInvoiceIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedInvoices: PropTypes.func.isRequired,
};

export default RejectRatification;
