import React from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useBooleanState } from '@fingo/lib/hooks';
import Delete from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import { LoadingButton } from '@mui/lab';
import {
  INVOICES_RELATED_COMPANY_ANNEX,
  PENDING_SIGNATURE,
  DELETE_RELATED_ANNEX,
} from '@fingo/lib/graphql';
import { useTheme } from '@emotion/react';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import FingoTable from '@fingo/lib/components/tables/FingoTable';
import DeleteAnnexColumns from './DeleteAnnexColumns';

const DeleteAnnexComponent = ({ invoice }) => {
  const [open, setOpen] = useBooleanState();
  const theme = useTheme();
  const SUCCESS = theme.palette.success.main;
  const annexInvoice = invoice.pendingsignaturecontractannexinvoice;
  const [loadRelatedInvoicesAnnex, { data, loading }] = useLazyQuery(
    INVOICES_RELATED_COMPANY_ANNEX,
    {
      variables: { invoiceId: invoice.id },
      onCompleted: setOpen,
    },
  );
  const [deleteRelatedAnnex, { loading: loadingMutation }] = useMutation(
    DELETE_RELATED_ANNEX,
    {
      variables: { annexId: annexInvoice?.pendingSignatureDocument?.id },
      refetchQueries: [PENDING_SIGNATURE],
      onCompleted: setOpen,
    },
  );
  const relatedInvoices = (
    data
      ?.invoice
      ?.pendingsignaturecontractannexinvoice
      ?.pendingSignatureDocument
      ?.invoices
      ?.edges.map((edge) => edge.node) ?? []
  );
  return (
    <>
      <Tooltip title={annexInvoice ? 'Eliminar Anexo Asociado' : 'Sin Anexo Asociado'}>
        <span>
          <LoadingButton
            sx={{ color: SUCCESS }}
            disabled={!annexInvoice}
            loading={loading}
            onClick={loadRelatedInvoicesAnnex}
          >
            <Delete />
          </LoadingButton>
        </span>
      </Tooltip>
      <FingoDialog
        title="Eliminar Anexo Asociado"
        subtitle="Vas a eliminar el anexo de contrato de las siguientes facturas"
        open={open}
        handleClose={setOpen}
        maxWidth="sm"
        fullWidth
        dialogActionButton={(
          <LoadingButton
            color="primary"
            variant="contained"
            size="small"
            onClick={deleteRelatedAnnex}
            loading={loadingMutation}
          >
            Confirmar
          </LoadingButton>
      )}
      >
        <Stack spacing={1} direction="column">
          <FingoTable
            rows={relatedInvoices}
            columns={DeleteAnnexColumns}
            includeHeaders={[
              'folio',
              'receiver',
              'dateIssued',
              'amountWithIva',
            ]}
            pagination={false}
          />
        </Stack>
      </FingoDialog>
    </>
  );
};

DeleteAnnexComponent.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    pendingsignaturecontractannexinvoice: PropTypes.shape({
      id: PropTypes.string.isRequired,
      pendingSignatureDocument: PropTypes.shape({
        id: PropTypes.string.isRequired,
        globalAppId: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
};

export default DeleteAnnexComponent;
