import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import PeopleIcon from '@mui/icons-material/People';
import { useGetBackOfficeUser } from '@fingo/lib/hooks';

const CommercialExecutiveFilter = ({ filter, setFilter }) => {
  const currentUser = useGetBackOfficeUser();
  if (currentUser?.executive?.isCommercialExecutive || filter === null) return null;
  return (
    <Tooltip title="Facturas asignadas al equipo comercial">
      <IconButton onClick={() => setFilter(!filter)}>
        <PeopleIcon color={filter ? 'primary' : 'text.main'} />
      </IconButton>
    </Tooltip>
  );
};

CommercialExecutiveFilter.propTypes = {
  filter: PropTypes.bool.isRequired,
  setFilter: PropTypes.func.isRequired,
};

export default CommercialExecutiveFilter;
