import React, { useCallback, useState } from 'react';
import { ArrayOfId } from '@fingo/lib/propTypes';
import { useBooleanState, useEnum, useTextFieldInput } from '@fingo/lib/hooks';
import { GET_RATIFICATION_ACTIONS_ENUM, GET_RATIFICATION_CHANNEL_ENUM, INVOICES_RATIFICATION } from '@fingo/lib/graphql';
import LoadingIconButtonTooltip from '@fingo/lib/components/buttons/LoadingIconButtonTooltip';
import EventNoteIcon from '@mui/icons-material/EventNote';
import useInvoicesIds from '@fingo/lib/hooks/useInvoicesIds';
import OperativeStepperDialog from '@fingo/lib/components/dialogs/OperativeStepperDialog';
import { CONTACT_TYPES } from '@fingo/lib/constants';
import useCreateRatificationManualManagement from '../../../../../hooks/useCreateRatificationManualManagement';
import LastStepManualManagement from './LastStepManuelManagement';

const ManualManagement = ({ selectedInvoiceIds }) => {
  const [selectedContactsIds, setSelectedContactsIds] = useState([]);
  const [open, toggleOpen,, closeDialog] = useBooleanState(false);
  const { invoices } = useInvoicesIds(selectedInvoiceIds, INVOICES_RATIFICATION);
  const [currentStep, setCurrentStep] = useState(0);
  const [firstStepOptions, selectedOption, setSelectedOption] = useEnum(
    GET_RATIFICATION_ACTIONS_ENUM,
  );
  const [channelOptions, selectedChannel, setSelectedChannel] = useEnum(
    GET_RATIFICATION_CHANNEL_ENUM,
  );
  const [comment, setComment, resetComment] = useTextFieldInput();
  const [responded, toggleResponded] = useBooleanState(false);
  const clean = useCallback(() => {
    setSelectedContactsIds([]);
    setSelectedOption('');
    resetComment();
    setSelectedChannel('');
    closeDialog();
    setCurrentStep(0);
  }, []);
  const sendManagement = useCreateRatificationManualManagement(
    {
      invoices,
      ratificationContactsIds: selectedContactsIds,
      ratificationActionType: selectedOption,
      ratificationComment: comment,
      ratificationChannel: selectedChannel,
      responded,
    },
    clean,
  );
  const stepToTitle = {
    0: 'Selecciona el tipo de gestión',
    1: 'Contactos de la gestión',
    2: 'Canal de la gestión',
  };
  const receivers = [...new Set(invoices.map((invoice) => invoice.receiver))];
  return (
    <>
      <LoadingIconButtonTooltip
        color="secondary"
        disabled={receivers.length !== 1}
        onClick={toggleOpen}
        tooltipTitle="Gestión manual"
      >
        <EventNoteIcon />
      </LoadingIconButtonTooltip>
      {receivers.length === 1 && (
        <OperativeStepperDialog
          open={open}
          onClose={clean}
          firstStepOptions={firstStepOptions}
          setOptions={setSelectedOption}
          selectedOption={selectedOption || ''}
          selectedContactsIds={selectedContactsIds}
          setSelectedContactsIds={setSelectedContactsIds}
          selectedDocuments={selectedInvoiceIds}
          managersAndAttachs={[]}
          setManagersAndAttachs={() => {}}
          masterEntityTarget={receivers[0]}
          operationType={CONTACT_TYPES.RATIFICATION}
          showAllContactTypes
          stepToTitle={stepToTitle}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          onSubmit={sendManagement[0]}
          loadingSubmit={sendManagement[1].loading}
          obligatoryContactId={false}
          lastStepComponent={(
            <LastStepManualManagement
              responded={responded}
              toggleResponded={toggleResponded}
              selectedChannel={selectedChannel}
              setSelectedChannel={setSelectedChannel}
              channelOptions={channelOptions}
              comment={comment}
              setComment={setComment}
            />
          )}
        />
      )}
    </>
  );
};

ManualManagement.propTypes = {
  selectedInvoiceIds: ArrayOfId.isRequired,
};

export default ManualManagement;
