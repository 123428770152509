import React, { useCallback, useState } from 'react';
import { ArrayOfId } from '@fingo/lib/propTypes';
import EmailIcon from '@mui/icons-material/Email';
import { useBooleanState, useEnum, useInvoicesIds } from '@fingo/lib/hooks';
import { GET_RATIFICATION_MAIL_ENUM, INVOICES_RATIFICATION } from '@fingo/lib/graphql';
import CheckboxGroup from '@fingo/lib/components/selects/CheckboxGroup';
import LoadingIconButtonTooltip from '@fingo/lib/components/buttons/LoadingIconButtonTooltip';
import OperativeStepperDialog from '@fingo/lib/components/dialogs/OperativeStepperDialog';
import { CONTACT_TYPES } from '@fingo/lib/constants';
import useSendRatificationMail from '../../../../../hooks/useSendRatificationMail';

const SendRatificationMail = ({ selectedInvoiceIds }) => {
  const [selectedContactIds, setSelectedContactIds] = useState([]);
  const [open, toggleOpen] = useBooleanState(false);
  const [withCessionCertificate, toggleWithCessionCertificate] = useBooleanState(false);
  const { invoices } = useInvoicesIds(selectedInvoiceIds, INVOICES_RATIFICATION);
  const [currentStep, setCurrentStep] = useState(0);
  const [firstStepOptions, selectedOption, setSelectedOption] = useEnum(GET_RATIFICATION_MAIL_ENUM);
  const clean = useCallback(() => {
    setSelectedContactIds([]);
    setSelectedOption('');
    toggleOpen();
    setCurrentStep(0);
  }, []);
  const sendMail = useSendRatificationMail(
    selectedContactIds,
    selectedOption,
    withCessionCertificate,
    selectedInvoiceIds,
    clean,
  );
  const stepToTitle = {
    0: 'Selecciona el tipo de correo',
    1: 'A quien quieres enviar',
    2: 'Confirmar',
  };
  const receivers = [...new Set(invoices.map((invoice) => invoice.receiver))];
  return (
    <>
      <LoadingIconButtonTooltip
        color="primary"
        disabled={receivers.length !== 1}
        onClick={toggleOpen}
        tooltipTitle="Enviar mail de ratificación"
      >
        <EmailIcon />
      </LoadingIconButtonTooltip>
      {receivers.length === 1 && (
        <OperativeStepperDialog
          open={open}
          onClose={clean}
          firstStepOptions={firstStepOptions}
          setOptions={setSelectedOption}
          selectedOption={selectedOption || ''}
          selectedContactsIds={selectedContactIds}
          setSelectedContactsIds={setSelectedContactIds}
          selectedDocuments={selectedInvoiceIds}
          managersAndAttachs={[]}
          setManagersAndAttachs={() => {}}
          masterEntityTarget={receivers[0]}
          operationType={CONTACT_TYPES.RATIFICATION}
          showAllContactTypes
          stepToTitle={stepToTitle}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          onSubmit={sendMail[0]}
          loadingSubmit={sendMail[1].loading}
          lastStepComponent={(
            <CheckboxGroup
              options={[
                {
                  checked: withCessionCertificate,
                  label: '',
                  handleChange: toggleWithCessionCertificate,
                },
              ]}
            />
          )}
          paperProps={{ maxWidth: 900 }}
        />
      )}
    </>
  );
};

SendRatificationMail.propTypes = {
  selectedInvoiceIds: ArrayOfId.isRequired,
};

export default SendRatificationMail;
