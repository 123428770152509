import React from 'react';
import AbstractRatificationPanel from './AbstractRatification';

const RatificationWithoutPriority = () => {
  const includeHeaders = [
    'folio',
    'company_MasterEntity_Name',
    'receiver_Ratification',
    'offerDateToPay',
    'dateIssued',
    'amountWithIva',
    'amountUsedInEntityLine',
    'siiStatus',
    'cessionStatus',
    'executiveAssigned',
    'ratificationmanager_Ratificator_LastName',
    'ratificationActions',
    'operationTimeDiff',
  ];
  return (
    <AbstractRatificationPanel
      includeHeaders={includeHeaders}
      ratificationStatus="NON_PRIORITY"
      panelId="non-priority-ratification"
      viewTitle="Facturas sin Prioridad"
    />
  );
};

export default RatificationWithoutPriority;
