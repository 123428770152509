import React from 'react';
import AlertsGrid from '@fingo/lib/components/grids/AlertsGrid';
import { InvoiceType } from '@fingo/lib/propTypes';
import { ratificationDocumentsReviewInvoiceAlerts } from '../../../helpers/ratification-documents-review';

const OCHesAlerts = ({ invoice }) => (
  <AlertsGrid
    alerts={ratificationDocumentsReviewInvoiceAlerts(invoice)}
  />
);

OCHesAlerts.propTypes = {
  invoice: InvoiceType.isRequired,
};

export default OCHesAlerts;
