import React from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/Check';
import ResolveRatification from './ResolveRatification';

const AcceptRatification = ({ selectedInvoiceIds, setSelectedInvoices }) => {
  const stepToTitle = {
    0: 'Seleccione el motivo por qué se aceptan estas facturas',
    1: 'Ingrese que contacto ratificó',
    2: 'Adjuntar archivo',
  };
  return (
    <ResolveRatification
      selectedInvoiceIds={selectedInvoiceIds}
      setSelectedInvoices={setSelectedInvoices}
      accepted
      stepToTitle={stepToTitle}
      loadingIcon={<CheckIcon />}
      loadingIconColor="success"
      iconTooltip="Aceptar"
    />
  );
};

AcceptRatification.propTypes = {
  selectedInvoiceIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedInvoices: PropTypes.func.isRequired,
};

export default AcceptRatification;
