import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import { useMasterEntityPreColumns } from '@fingo/lib/constants';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import PropTypes from 'prop-types';
import useRatificationDebtorsColumns from './columns/useRatificationDebtorsColumns';
import DebtorsActions from '../../../collection/components/Debtors/DebtorsActions';

const AbstractDebtorsInRatification = ({ includeHeaders, queryDocument, title }) => {
  const columns = useMasterEntityPreColumns(useRatificationDebtorsColumns());
  const country = useGetCountryFromUrl();
  const [priority, setPriority] = useState();
  return (
    <FingoMainView
      id="ratification-debtors-list"
      query={queryDocument}
      queryCustomVariables={{
        inRatification: true,
        countryId: country?.id,
        debtor_RatificationPriority_Value: priority,
      }}
      slots={{
        header: PaperHeader,
        table: FingoDataGrid,
        actions: DebtorsActions,
      }}
      slotProps={{
        header: {
          viewTitle: title,
          finder: {
            searchPlaceHolder: 'Buscar deudor',
          },
        },
        table: {
          columns,
          noRowsMessage: () => <Typography>Sin deudores</Typography>,
          initialOrderBy: 'debtor_RatificationPriority_Value',
          includeHeaders,
          rowsPerPageOptions: [15, 25, 50, 100],
          initialPageSize: 50,
        },
        actions: {
          debtorCollectionPriority: priority,
          setDebtorCollectionPriority: setPriority,
        },
      }}
    />
  );
};

AbstractDebtorsInRatification.propTypes = {
  includeHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  queryDocument: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,
};

export default AbstractDebtorsInRatification;
