import React from 'react';
import PropTypes from 'prop-types';
import {
  Stack,
  Button,
  Checkbox,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useBooleanState, useSnackBars } from '@fingo/lib/hooks';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { useMutation } from '@apollo/client';
import { UPDATE_BUREAUS, GET_COMPANIES_BUREAUS } from '@fingo/lib/graphql';

const RiskBureausDialog = ({ rightSideSelectedId }) => {
  const { addAlert } = useSnackBars();
  const [open, toggleOpen] = useBooleanState();
  const [updateDicom, setDicomChecked] = useBooleanState();
  const [updateBusinessProfile, setBusinessProfileChecked] = useBooleanState();
  const [updateCurrentBehaviour, setCurrentBehaviourChecked] = useBooleanState();
  const [updateBureaus, { loading }] = useMutation(
    UPDATE_BUREAUS,
    {
      variables: {
        ids: rightSideSelectedId,
        updateDicom,
        updateBusinessProfile,
        updateCurrentBehaviour,
        delay: true,
      },
      onCompleted: () => {
        toggleOpen(!open);
        addAlert({
          id: 'update-bureaus',
          message: 'Solicitud en curso. Recargue en unos minutos más.',
        });
      },
      refetchQueries: [GET_COMPANIES_BUREAUS],
    },
  );
  return (
    <>
      <Button
        disabled={rightSideSelectedId.length === 0}
        variant="contained"
        size="small"
        onClick={toggleOpen}
      >
        Actualizar Seleccionadas
      </Button>
      <FingoDialog
        key="update-bureaus"
        id="update-bureaus-dialog"
        title="Actualizar Bureaus"
        open={open}
        handleClose={toggleOpen}
        maxWidth="md"
        fullWidth
        dialogActionButton={(
          <LoadingButton
            color="primary"
            onClick={updateBureaus}
            loading={loading}
            disabled={!updateDicom && !updateBusinessProfile && !updateCurrentBehaviour}
          >
            Actualizar
          </LoadingButton>
        )}
      >
        <Stack justifyContent="center" sx={{ marginTop: 2 }} direction="row" spacing={2}>
          <Stack direction="row">
            <Typography variant="h6" sx={{ alignSelf: 'center' }}>Actualizar Dicom</Typography>
            <Checkbox
              color="primary"
              checked={updateDicom}
              onClick={(event) => setDicomChecked(event.target.checked)}
            />
          </Stack>
          <Stack direction="row">
            <Typography variant="h6" sx={{ alignSelf: 'center' }}>Actualizar Perfil de Empresa</Typography>
            <Checkbox
              color="primary"
              checked={updateBusinessProfile}
              onClick={(event) => setBusinessProfileChecked(event.target.checked)}
            />
          </Stack>
          <Stack direction="row">
            <Typography variant="h6" sx={{ alignSelf: 'center' }}>Actualizar Comportamiento Vigente</Typography>
            <Checkbox
              color="primary"
              checked={updateCurrentBehaviour}
              onClick={(event) => setCurrentBehaviourChecked(event.target.checked)}
            />
          </Stack>
        </Stack>
      </FingoDialog>
    </>
  );
};

RiskBureausDialog.propTypes = {
  rightSideSelectedId: PropTypes.arrayOf(
    PropTypes.string,
  ),
};

RiskBureausDialog.defaultProps = {
  rightSideSelectedId: [],
};

export default RiskBureausDialog;
