import React from 'react';
import PropTypes from 'prop-types';
import { CurrencyFilter } from '@fingo/lib/components/filters';
import { useGetCurrenciesFromCompany } from '@fingo/lib/hooks';
import UploadSignatureDialog from './UploadSignatureDialog';
import RaiseOperativeRequestIconButton from '../../../../support/components/buttons/RaiseOperativeRequestIconButton';

const PendingSignatureActions = ({
  masterEntity,
  selectedDocuments,
  data,
  currency,
  setCurrency,
}) => {
  const currencies = useGetCurrenciesFromCompany({ masterEntity });
  return (
    <>
      <RaiseOperativeRequestIconButton documentIds={selectedDocuments} />
      <UploadSignatureDialog
        companyId={masterEntity.id}
        selectedDocuments={selectedDocuments}
        invoices={data}
        currency={currency}
      />
      <CurrencyFilter
        currencies={currencies}
        currency={currency}
        setCurrency={setCurrency}
      />
    </>
  );
};

PendingSignatureActions.propTypes = {
  masterEntity: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  selectedDocuments: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      amountWithIva: PropTypes.shape,
    }),
  ).isRequired,
  currency: PropTypes.string.isRequired,
  setCurrency: PropTypes.func.isRequired,
};

export default PendingSignatureActions;
