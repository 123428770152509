import React from 'react';
import { NON_PRIORITY_RATIFICATION_MASTER_ENTITY } from '@fingo/lib/graphql';
import { FadeSwitch } from '@fingo/lib/components/switches';
import { Route, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import AbstractDebtorsInRatification from './AbstractDebtorsInRatification';
import RatificationWithoutPriority from './RatificationWithoutPriority';

const NonPriorityRatificationDebtors = () => {
  const { path: routePath } = useRouteMatch();
  const includeHeaders = [
    'name',
    'documentsCount',
    'totalAmount',
    'debtor_RatificationExecutive__firstName',
    'lastAction',
    'ratificationActions',
  ];
  return (
    <FadeSwitch depth={5}>
      <Route id="priority-ratification-debtor-profile" key="profile" path={`${routePath}/:debtorId`}>
        <RatificationWithoutPriority />
      </Route>
      <Route id="priority-ratification-debtors" key="index">
        <AbstractDebtorsInRatification
          includeHeaders={includeHeaders}
          queryDocument={NON_PRIORITY_RATIFICATION_MASTER_ENTITY}
          title="Deudores en ratificación sin prioridad"
        />
      </Route>
    </FadeSwitch>
  );
};

export default NonPriorityRatificationDebtors;
