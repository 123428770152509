import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { ValidationTextFieldInput } from '@fingo/lib/components/inputs';

const UploadSignaturePersonComponent = (
  {
    personArray,
    handleSetPersonArray,
    handleDeletePersonArray,
    label,
    handleInformation,
  },
) => {
  const formatter = (rfc) => rfc?.toUpperCase().replace(/ /g, '') || '';
  return (
    <>
      <Stack direction="row" spacing={1} sx={{ marginLeft: 2 }} alignItems="center">
        <Typography variant="h6" sx={{ paddingTop: 0.8 }}>
          {label}
        </Typography>
        <AddCircleIcon
          color="primary"
          onClick={handleSetPersonArray}
          sx={{ cursor: 'pointer' }}
        />
      </Stack>
      {personArray.map((person) => (
        <Stack spacing={2}>
          <Stack direction="row" spacing={1} sx={{ marginLeft: 2 }} alignItems="center">
            <Avatar sx={{ height: 30, width: 30, bgcolor: '#838383', fontSize: 12 }}>{person.id + 1}</Avatar>
            <Divider width="75%" />
            <Tooltip title={`Eliminar ${label} ${person.name}`}>
              <RemoveCircleIcon
                color="primary"
                onClick={() => handleDeletePersonArray(person.id)}
                sx={{ cursor: 'pointer' }}
              />
            </Tooltip>
          </Stack>
          <ValidationTextFieldInput
            id="rut"
            label="RFC"
            validationtype="rfc"
            name="nationalIdentifier"
            type="text"
            variant="outlined"
            fullWidth
            value={person.rut}
            onChange={(e) => {
              handleInformation(e.target.id, formatter(e.target.value), person.id);
            }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            id="name"
            label="Nombre"
            onChange={(e) => handleInformation(e.target.id, e.target.value, person.id)}
            value={person.name}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            id="address"
            label="Dirección"
            onChange={(e) => handleInformation(e.target.id, e.target.value, person.id)}
            value={person.address}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </Stack>
      ))}
    </>
  );
};

UploadSignaturePersonComponent.propTypes = {
  handleInformation: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  personArray: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    rut: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  handleSetPersonArray: PropTypes.func.isRequired,
  handleDeletePersonArray: PropTypes.func.isRequired,
};

export default UploadSignaturePersonComponent;
