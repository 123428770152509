import React, { useCallback, useState } from 'react';
import {
  Stack,
  Button,
  Checkbox,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useBooleanState, useSnackBars, useInputRut } from '@fingo/lib/hooks';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { useMutation } from '@apollo/client';
import { UPDATE_BUREAUS, CREATE_MASTER_ENTITY, GET_COMPANIES_BUREAUS } from '@fingo/lib/graphql';
import { ValidationTextFieldInput } from '@fingo/lib/components/inputs';

const RiskBureausNewCompanyDialog = () => {
  const { nationalIdentifier: rut, updateNationalIdentifier: updateRut, isValid } = useInputRut();
  const [masterEntityId, setMasterEntityId] = useState('');
  const { addAlert } = useSnackBars();
  const [open, toggleOpen] = useBooleanState();
  const [updateDicom, setDicomChecked] = useBooleanState();
  const [updateBusinessProfile, setBusinessProfileChecked] = useBooleanState();
  const [updateCurrentBehaviour, setCurrentBehaviourChecked] = useBooleanState();
  const [updateBureaus, { loading }] = useMutation(
    UPDATE_BUREAUS,
    {
      variables: {
        ids: [masterEntityId],
        updateDicom,
        updateBusinessProfile,
        updateCurrentBehaviour,
        delay: false,
      },
      onCompleted: () => {
        updateRut(null);
        toggleOpen(!open);
        addAlert({
          id: 'update-bureaus',
          message: 'Bureaus Actualizados',
        });
      },
      refetchQueries: [GET_COMPANIES_BUREAUS],
    },
  );
  const [masterEntityMutation, { loading: loadingMasterEntityMutation }] = useMutation(
    CREATE_MASTER_ENTITY,
    {
      variables: {
        rutWithDv: rut.raw,
      },
      onCompleted: ({ createMasterEntity }) => {
        setMasterEntityId(createMasterEntity.masterEntity.id);
        updateBureaus();
      },
    },
  );
  const onChangeInput = useCallback(
    (event) => {
      updateRut(event.target.value);
    },
    [updateRut],
  );
  return (
    <>
      <Button
        variant="contained"
        size="small"
        onClick={toggleOpen}
      >
        Actualizar por Rut
      </Button>
      <FingoDialog
        key="update-bureaus"
        id="update-bureaus-dialog"
        title="Actualizar por Rut"
        open={open}
        handleClose={() => {
          updateRut(null);
          toggleOpen();
        }}
        maxWidth="md"
        fullWidth
        dialogActionButton={(
          <LoadingButton
            color="primary"
            onClick={masterEntityMutation}
            loading={loading || loadingMasterEntityMutation}
            disabled={
              (!updateDicom && !updateBusinessProfile && !updateCurrentBehaviour) || !isValid
            }
          >
            Actualizar
          </LoadingButton>
        )}
      >
        <Stack direction="column">
          <ValidationTextFieldInput
            name="rut"
            type="text"
            validationtype="rut"
            label="Rut Empresa"
            variant="standard"
            value={rut.formatted}
            onChange={onChangeInput}
            sx={{ width: '50%', alignSelf: 'center' }}
          />
          <Stack justifyContent="center" sx={{ marginTop: 2 }} direction="row" spacing={2}>
            <Stack direction="row">
              <Typography variant="h6" sx={{ alignSelf: 'center' }}>Actualizar Dicom</Typography>
              <Checkbox
                color="primary"
                checked={updateDicom}
                onClick={(event) => setDicomChecked(event.target.checked)}
              />
            </Stack>
            <Stack direction="row">
              <Typography variant="h6" sx={{ alignSelf: 'center' }}>Actualizar Perfil de Empresa</Typography>
              <Checkbox
                color="primary"
                checked={updateBusinessProfile}
                onClick={(event) => setBusinessProfileChecked(event.target.checked)}
              />
            </Stack>
            <Stack direction="row">
              <Typography variant="h6" sx={{ alignSelf: 'center' }}>Actualizar Comportamiento Vigente</Typography>
              <Checkbox
                color="primary"
                checked={updateCurrentBehaviour}
                onClick={(event) => setCurrentBehaviourChecked(event.target.checked)}
              />
            </Stack>
          </Stack>
        </Stack>
      </FingoDialog>
    </>
  );
};

export default RiskBureausNewCompanyDialog;
