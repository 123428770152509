import React from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MuiLink from '@mui/material/Link';
import { useIsMobile } from '@fingo/lib/hooks';
import { addMoneyWithCurrency, formatMoney } from '@fingo/lib/helpers/money';
import { Link } from 'react-router-dom';
import { ActionCell, ActionDrawerCell } from '@fingo/lib/components/cells';
import DrawerHeader from '@fingo/lib/components/drawer/DrawerHeader';
import CollectionActions from '@fingo/lib/components/drawer/CollectionActions';
import { formatRut } from '@fingo/lib/helpers';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { filterByUniqueObjectsByKey } from '@fingo/lib/helpers/arrays';
import CompanyBlacklistComponent from '@fingo/lib/components/cells/CompanyBlacklistComponent';
import CompanyDebtsSummary from '@fingo/lib/components/cells/CompanyDebtsSummary';

const useRatificationDebtorsColumns = () => {
  const isMobile = useIsMobile();
  const { path: routePath } = useRouteMatch();
  return [
    {
      field: 'name',
      headerName: 'Deudor',
      type: 'string',
      minWidth: 210,
      sortable: true,
      filterable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <Stack alignItems="flex-start" justifyContent="center">
          <Stack direction="row">
            <MuiLink
              component={Link}
              to={`${routePath}/${row.id}`}
              color="primary"
              variant="body2"
              align="left"
              rel="noopener noreferrer"
              underline="hover"
            >
              {row.name || formatRut(row.rut)}
            </MuiLink>
            <CompanyBlacklistComponent
              masterEntity={row}
              disableDisplay
            />
            <CompanyDebtsSummary masterEntity={row} type="receiver" />
          </Stack>
          {!isMobile && <Typography variant="subtitle1">{formatRut(row.rut)}</Typography>}
        </Stack>
      ),
    },
    {
      field: 'documentsCount',
      headerName: 'Docs',
      type: 'string',
      maxWidth: 60,
      sortable: false,
      filterable: false,
      flex: 1,
      valueGetter: (params) => params.row.invoicesInRatification.length,
      renderCell: (params) => (
        <Grid container alignItems="center" justifyContent="center">
          <Typography variant="body2">{params.value}</Typography>
        </Grid>
      ),
    },
    {
      field: 'totalAmount',
      headerName: 'Total en ratificación',
      type: 'string',
      sortable: false,
      filterable: false,
      minWidth: 120,
      flex: 1,
      valueGetter: (params) => {
        const managers = params.row.invoicesInRatification;
        const value = managers.reduce(
          (prev, curr) => addMoneyWithCurrency(prev, curr.amountWithIva),
          0,
        );
        return value;
      },
      renderCell: (params) => (
        <Grid container alignItems="center" justifyContent="flex-start">
          <Typography variant="body1">${formatMoney(params.value.amount)}</Typography>
        </Grid>
      ),
    },
    {
      field: 'lastAction',
      headerName: 'Última gestión',
      type: 'string',
      sortable: false,
      filterable: false,
      minWidth: 130,
      flex: 1,
      valueGetter: (params) => {
        const actions = params.row.invoicesInRatification.map(
          (invoice) => invoice.ratificationmanager?.actions,
        ).flat();
        const newAc = [...actions].sort((a, b) => b.id - a.id);
        return newAc[0];
      },
      renderCell: ({ value }) => <ActionCell action={value} />,
    },
    {
      field: 'ratificationActions',
      headerName: 'Ratificación',
      type: 'string',
      sortable: false,
      width: 80,
      valueGetter: (params) => {
        const actions = params.row.invoicesInRatification.map(
          (invoice) => invoice.ratificationmanager.actions,
        ).flat();
        return filterByUniqueObjectsByKey(actions, 'id').sort((a, b) => b.id - a.id);
      },
      renderCell: ({ value, row }) => (
        <ActionDrawerCell
          title={`Gestiones de ratificación deudor ${row.name}`}
          actions={value}
          headerComponent={DrawerHeader}
          contentComponent={CollectionActions}
          drawerId={row.id}
        />
      ),
    },
  ];
};

export default useRatificationDebtorsColumns;
