import React from 'react';
import { useMutation } from '@apollo/client';
import LoadingIconButtonTooltip from '@fingo/lib/components/buttons/LoadingIconButtonTooltip';
import { FETCH_MANY_TRACE } from '@fingo/lib/graphql';
import UpdateIcon from '@mui/icons-material/Update';
import PropTypes from 'prop-types';

const FetchTrace = ({ selectedInvoiceIds }) => {
  const [fetchManyTraceMutation, fetchTraceData] = useMutation(
    FETCH_MANY_TRACE,
    { variables: { invoiceId: selectedInvoiceIds } },
  );
  return (
    <LoadingIconButtonTooltip
      color="info"
      onClick={fetchManyTraceMutation}
      loading={fetchTraceData.loading}
      disabled={!selectedInvoiceIds.length}
      tooltipTitle="Actualizar las trazas"
    >
      <UpdateIcon />
    </LoadingIconButtonTooltip>
  );
};

FetchTrace.propTypes = {
  selectedInvoiceIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default FetchTrace;
