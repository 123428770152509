import React from 'react';
import PropTypes from 'prop-types';
import RiskBureausDialog from './RiskBureausDialog';
import RiskBureausNewCompanyDialog from './RiskBureausNewCompany';

const RiskBureausActions = ({ rightSideSelectedId }) => (
  <>
    <RiskBureausNewCompanyDialog />
    <RiskBureausDialog rightSideSelectedId={rightSideSelectedId} />
  </>
);

RiskBureausActions.propTypes = {
  rightSideSelectedId: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default RiskBureausActions;
