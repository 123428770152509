import React from 'react';
import { PRIORITY_RATIFICATION_MASTER_ENTITY } from '@fingo/lib/graphql';
import { FadeSwitch } from '@fingo/lib/components/switches';
import { Route, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import AbstractDebtorsInRatification from './AbstractDebtorsInRatification';
import RatificationWithPriority from './RatificationWithPriority';

const PriorityRatificationDebtors = () => {
  const { path: routePath } = useRouteMatch();
  const includeHeaders = [
    'name',
    'documentsCount',
    'totalAmount',
    'debtor_RatificationExecutive__firstName',
    'debtor_RatificationPriority_Value',
    'lastAction',
    'ratificationActions',
  ];
  return (
    <FadeSwitch depth={5}>
      <Route id="priority-ratification-debtor-profile" key="profile" path={`${routePath}/:debtorId`}>
        <RatificationWithPriority />
      </Route>
      <Route id="priority-ratification-debtors" key="index">
        <AbstractDebtorsInRatification
          includeHeaders={includeHeaders}
          queryDocument={PRIORITY_RATIFICATION_MASTER_ENTITY}
          title="Deudores en ratificación con prioridad"
        />
      </Route>
    </FadeSwitch>
  );
};

export default PriorityRatificationDebtors;
