import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import { useMasterEntityPreColumns } from '@fingo/lib/constants';
import { GET_COMPANIES_BUREAUS } from '@fingo/lib/graphql/customers';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import RiskBureausColumns from '../../constants/risk-bureaus-columns';
import RiskBureausActions from './RiskBureausActions';

const RiskBureaus = () => {
  const [rightSideSelectedId, setRightSideSelectedId] = useState([]);
  const includeHeaders = ['name', 'currentBehavior', 'currentBusinessProfile', 'dicom'];
  const columns = useMasterEntityPreColumns(
    RiskBureausColumns,
  ).filter((column) => includeHeaders.includes(column.field));
  const country = useGetCountryFromUrl();
  return (
    <>
      <FingoMainView
        id="risk-bureaus"
        query={GET_COMPANIES_BUREAUS}
        queryCustomVariables={{
          countryId: country?.id,
        }}
        slots={{
          header: PaperHeader,
          table: FingoDataGrid,
          actions: RiskBureausActions,
        }}
        slotProps={{
          header: {
            viewTitle: 'Consultas Bureaus',
            finder: {
              searchPlaceHolder: 'Buscar empresa',
            },
          },
          actions: {
            rightSideSelectedId,
          },
          table: {
            columns,
            checkboxSelection: true,
            noRowsMessage: () => <Typography variant="body2" />,
            onSelectionModelChange: setRightSideSelectedId,
            initialOrderBy: 'name',
            rowsPerPageOptions: [10, 15],
          },
        }}
      />
    </>
  );
};

export default RiskBureaus;
