const setArray = (endArray, setFunc) => {
  setFunc(endArray.map((end, index) => (
    {
      id: index,
      masterEntityId: end.relatedPerson.id ?? null,
      rut: end.relatedPerson.displayNationalIdentifier ?? '',
      name: end.relatedPerson.name ?? '',
      address: end.relatedPerson.address ?? '',
    })));
};

const HandleResponse = (company, setSelectedCompany, setLegalRepresentative, setEndorsements) => {
  const filteredReps = company.legalRepresentative.filter((rep) => rep.source === 'PENDING_SIGNATURE_CONTRACT_ANNEX');
  setSelectedCompany(company);
  setArray(filteredReps, setLegalRepresentative);
  setArray(company.endorsements, setEndorsements);
};

export default HandleResponse;
