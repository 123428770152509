import React from 'react';
import AbstractRatificationPanel from './AbstractRatification';

const RatificationWithPriority = () => {
  const includeHeaders = [
    'folio',
    'company_MasterEntity_Name',
    'receiver_Ratification',
    'offerDateToPay',
    'dateIssued',
    'amountWithIva',
    'amountUsedInEntityLine',
    'siiStatus',
    'ratificationmanager_RatificationPriority_Value',
    'executiveAssigned',
    'ratificationmanager_Ratificator_LastName',
    'ratificationActions',
    'ratificationManagerTimeDiff',
  ];
  return (
    <AbstractRatificationPanel
      includeHeaders={includeHeaders}
      ratificationStatus="PRIORITY"
      panelId="priority-ratification"
      viewTitle="Facturas con Prioridad"
    />
  );
};

export default RatificationWithPriority;
