import React from 'react';
import { FingoPaper } from '@fingo/lib/components/layout';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import { useGetUser } from '@fingo/lib/hooks';
import { pendingSignatureTabs } from '../routes';

const PendingSignature = () => (
  <FingoPaper tabs={pendingSignatureTabs(
    useGetUser(),
    useGetCountryFromUrl(),
  )}
  />
);

export default PendingSignature;
