import React from 'react';
import DeleteAnnexComponent from './DeleteAnnexComponent';
import DownloadAnnexComponent from './DownloadAnnexComponent';

const useCustomPendingSignatureColumns = [
  {
    field: 'deleteAnnexFile',
    headerName: 'Eliminar Anexo',
    type: 'icon',
    headerAlign: 'center',
    align: 'center',
    minWidth: 50,
    sortable: false,
    filterable: false,
    flex: 0.3,
    renderCell: ({ row }) => <DeleteAnnexComponent invoice={row} />,
  },
  {
    field: 'annexFile',
    headerName: 'Anexo',
    type: 'icon',
    headerAlign: 'center',
    align: 'center',
    minWidth: 50,
    sortable: false,
    filterable: false,
    flex: 0.3,
    renderCell: ({ row }) => <DownloadAnnexComponent invoice={row} />,
  },
];

export default useCustomPendingSignatureColumns;
